<template>
  <td style="white-space:nowrap;"
    :class="{'flex-box-td':(showChart && index == 0),'py-0': keyName === 'reason'}"
    v-for="(item,index) in checkList"
    :key="index"
  >
    <div
      class="table-data-container"
      v-if="item=='事業所'"
    >{{ row[item]==null || row[item]=="" ?'未命名':row[item] }}</div>
    <div class="table-data-container" v-if="(item!='事業所' && item!='LBC' && item != '会社名' && item != '物件リスト')">
      <div v-html="row[item]"></div>
    </div>
    <div class="table-data-container click"  v-if="('会社名' == item && row[item])" style="width: unset;">
      <span @click="del_recommend(row.Corp_Num)">
          <i class="fa fa-trash"></i>
        </span>
      <span @click="goto(row,'corp')">
        {{row[item]}}
      </span>
    </div>
    <div class="table-data-container click"  v-if="('物件リスト' == item && row[item])" style="width: unset;">
      <span @click="goto(row,'building')">
        {{row[item]}}
      </span>
    </div>
    <div v-if="showChart && index == 0">
      <button
        @click="setChart"
        type="button"
        class="btn btn-blue waves-effect waves-light market-data-btn"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-bar-chart-2 icon-dual"
        >
          <line x1="18" y1="20" x2="18" y2="10" />
          <line x1="12" y1="20" x2="12" y2="4" />
          <line x1="6" y1="20" x2="6" y2="14" />
        </svg>
      </button>
    </div>
    <div v-if="showModal" class="modal popup-modal">
    <div class="modal-dialog">
      <div class="popup modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click="showModal = false">
            <span>×</span>
          </button>
        </div>
        <div class="modal-body w850" v-if="showModal">
          <div class="col-12">
            <apexchart
                type="line"
                :height="650"
                :options="option"
                :series="series"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-bg" @click="showModal = false"></div>
  </div>
  </td>
  
</template>

<script>
export default {
  name: "ListTableRow",
  emits:['del_recommend'],
  props: {
    row: Object,
    checkList: Array,
    showChart: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isPopup: false,
      showModal: false,
      option: {
        chart: {
          type: "line",
          stacked: false,
          height: 650,
          width: 800,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {    //线条类型
          curve: 'straight'
        },
        forecastDataPoints: {
          count: 0,
          fillOpacity: 0.3,
          strokeWidth: 0,
          dashArray: 5,
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {}
        },
        colors: ["#80bbad", "#dbd99a", "#d2785a", "#a388bf", "#3e7ca6"],
        annotations: {
          xaxis: [
            {
              x: "2022Q4",
              x2: "2024Q4",
              strokeDashArray: 0,
              fillColor: "#CAD1D3",
              borderColor: "#c2c2c2",
              borderWidth: 1,
              opacity: 0.4,
              offsetX: 0,
              offsetY: 0,
              label: {
                borderColor: "#CAD1D3",
                borderWidth: 1,
                borderRadius: 2,
                text: "",
                textAnchor: "middle",
                orientation: "vertical",
                position: "top",
                offsetX: 0,
                offsetY: -10,
                style: {
                  background: "#00E396",
                  color: "#fff",
                  fontSize: "10px",
                  fontWeight: 400,
                  cssClass: "",
                  padding: {
                    left: 5,
                    right: 5,
                    top: 2,
                    bottom: 2,
                  },
                },
              },
            },
          ],
          yaxis: [],
          points: [],
        },
        title: {
          text: '',
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          title: {
            text: "四半期",
          },
          labels: {
            rotate: 315,
            rotateAlways: true,
          },
          tickPlacement: "on",
          categories: [],
          convertedCatToNumeric: true,
        },
      },
      series: [
        {
          name: "百万円",
          data: [],
        },
      ],
      chart_name_arr:[
        '損益計算書',
        'キャッシュ･フロー'
      ],
      
    };
  },
  computed:{

  },
  mounted() {
    var that=this;
    this.option.yaxis.labels={formatter: function (value) {
        return that.format_money(value);
      }}
  },
  
  methods: {
    del_recommend(Corp_Num){
      this.$emit('del_recommend',Corp_Num);
    },
    format_money:function (num) {
      
      if(isNaN(num) || num==null || num==0){
       return num;
     }
     var result = '', counter = 0;
     //if(parseInt(num) == num) return num;
     num=num.toString();
     var numa=num.split('.');
     num=numa[0]
     for (var i = num.length - 1; i >= 0; i--) {
        counter++;
       result = num.charAt(i) + result;
       if (!(counter % 3) && i != 0 && num.charAt(i-1)!='-') { result = ',' + result; }
     }
     return result;
   },
    getSeries(){
      this.option.xaxis.categories = []
      this.series[0].data = []
      for (let k in this.row){
        console.log(k);
        console.log(this.chart_name_arr.includes(k));
        
        if(this.chart_name_arr.includes(k)){
          this.option.title.text = this.row[k];
          continue
        }
        this.option.xaxis.categories.push(k)
        this.series[0].data.push(this.replaceComma(this.row[k]))
      }
    },
    replaceComma(v){
      return v.replace(/,/g,'')
    },
    setChart() {
      this.getSeries()
      this.showModal = !this.showModal;
      console.log("图表数据：",this.option);
      
    },
    togglePopup: function () {
      setTimeout(() => {
        this.isPopup = !this.isPopup;
      }, 10);
    },
    goto(row,flag) {
      if(flag == 'building'){
        this.$router.push({path:`/build_info`,query:{BuildingID:row['BuildingID']}});
        return;
      }
      // this.$router.push({ path: `/CorpReference/0?dataType=100&dataName=${row['会社名']}&dataNumber=${row['Corp_Num']}`});
      this.$router.push({ path: `/CorpReference/0`, query:{dataType:100,dataName:row['会社名'],dataNumber:row['Corp_Num']} });
    },
  },
};
</script>

<style lang="scss" scoped>
.del-i{
    padding: 3px 10px;
    background-color: #303133;
    border-radius: 44px;
    color: #fff;
    cursor: pointer;
}
.btn-blue{
    color: #fff;
    background-color: #003f2d;
    border-color: #003f2d;
    height: 30px;
    width: 35px;
    padding: 5px 5px 5px 0;
}
.market-area{
    position: absolute;
    top: 5px;
    padding: 5px;
    right: 10px;
}
.flex-box-td {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  justify-content: space-between;
}
.btn-blue svg {
    color: #fff;
    border: 0;
}
.modal {
  z-index: 9999 !important;
}
.popup {
  background: #fff;
  border-radius: 0.15rem;
  box-shadow: 0 0.15rem 0.5rem rgba(0, 0, 0, 0.25);
  left: 50% !important;
  max-height: 85vh;
  min-height: 750px;
  width: 850px;
  position: fixed;

  transform: translateX(-50%) !important;
  z-index: 16;
}
.table.table-striped td {
  min-width: 10.5em;
  white-space: normal !important;
  word-wrap: break-word;
}

.table-data-container {
  max-height: 15rem;
  overflow-y: auto;
}

.focus-on .focused a {
  color: #fff;
}

.focus-on tr:not(.focused) .btn-white:hover {
  color: #fff;
  background-color: #003f2d;
  border-color: #003f2d;
}

.focus-on .focused a:hover {
  color: #003f2d;
}

.popup-modal {
  display: block;
}

.popup-bg {
  background: rgba(0, 0, 0, 0.08);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.popup {
  background: #fff;
  border-radius: 0.15rem;
  box-shadow: 0 0.15rem 0.5rem rgba(0, 0, 0, 0.25);
  left: 50% !important;
  max-height: 85vh;
  max-width: 90%;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  z-index: 16;
}

.popup iframe {
  width: 100%;
}

.fade-leave-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}
.table.table-striped tr td:first-child {
  background: #80bbad !important;
  color: #000 !important;
}

.table.table-striped tr.emptyd td:first-child {
  background: #003f2d !important;
  color: #fff;
}

.click {
  text-decoration: underline;
  cursor:pointer;
}
.table-data-container div,
.table-data-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 170px;
}
</style>