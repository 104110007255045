<template>
  <!-- Start Content-->
  <div>
    <div class="responsive-table-plugin btn-toolbar align-items-center justify-content-between">
      <div style="display: flex;justify-content: space-around;min-width: 520px;">
        <div class="form-inline md-form form-sm toolbar-search">
          <label>
            <div class="language">{{lan['industry']}}</div>: &nbsp;&nbsp;
          </label>
          <select class="form-control" v-model="queryObj.industry">
            <option value selected>{{$language('all')}}</option>
            <option v-for="(item,index) in industry_type" :key="index" :value="item.id">{{item.name}}</option>
          </select>
        </div>

        <!-- 2 -->
        <div class="form-inline md-form form-sm toolbar-search">
          <label>
            <div class="language">{{lan['lease']}}</div>:&nbsp;&nbsp;
          </label>
          <select class="form-control" v-model="queryObj.isLease">
            <option value selected>{{$language('all')}}</option>
            <option value="Yes">{{$language('Yes')}}</option>
            <option value="No">{{$language('No')}}</option>
          </select>
        </div>
        <!-- 2 -->

        <!-- 3 -->
        <div class="form-inline md-form form-sm toolbar-search">
          <label>
            <div class="language">{{lan['expansion_demand']}}</div>:&nbsp;&nbsp;
          </label>
          <select class="form-control" v-model="queryObj.expand">
            <option value >{{$language('all')}}</option>
            <option value="1">{{$language('扩张需求')}}</option>
            <option value="2">{{$language('集约需求')}}</option>
            <option value="3">{{$language('縮小需求')}}</option>
            <option value="4">{{$language('不明')}}</option>
          </select>
        </div>
        <!-- 3 -->

        <div class="form-inline toolbar-download" style="margin-left:10px;">
          <button type="button" class="btn btn-primary" @click="search">
            <i class="fas fa-search" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div style="display: flex;">
        <div class="form-inline toolbar-display">
          <div class="mr-2 toolbar-display-count">
            <span class="mr-1">
              <div class="lang" v-html="lan.limit_count"></div>
            </span>
            <div>
              <select class="form-control mt-0" v-model.lazy="queryObj.limit" @change="search">
                <option>20</option>
                <option>50</option>
                <option>100</option>
                <option>200</option>
                <option>500</option>
              </select>
            </div>
          </div>
        </div>
        <button
          @click="download"
          class="btn btn-primary"
          v-if="cur_space_id=='' || curindex!='tc5' "
        >
          <i class="mdi mdi-arrow-bottom-bold"></i>
          {{lan.download}}
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only">
          <div class="lang" v-html="lan.loading"></div>...
        </span>
      </div>
    </div>
    <div v-else-if="(!jsonErr && listData.length <= 0)">
      <!-- 表示項目無し -->
      <code>
        <div class="lang" v-html="lan.no_display_item_selected"></div>
      </code>
    </div>
    <div class="responsive-table-plugin" v-else-if="!jsonErr">
      <div class="table-rep-plugin">
        <div class="table-responsive" style="transform: scaleY(-1);">
         
          <table class="table table-striped focus-on" style="transform: scaleY(-1);">
            <thead>
              <tr>
                <th
                  v-for="tableHeader in tableHeaders"
                  :key="tableHeader"
                  :class="'table-row-' + tableHeader"
                >{{ $language(tableHeader) }}</th>
                <th></th>
              </tr>
            </thead>
            
            <tbody v-if="(jsonObj.count)">
              <tr
                v-for="(obj, idNum) in listData"
                :key="idNum"
                :class="{ 'emptyd' : obj['資産の部']!=undefined && (obj['資産の部']=='負債の部' || obj['資産の部']=='資本の部'),'recommend':viewParams.randIndex==1 && cur_recommend==idNum }"
              >
                <list-table-row3 v-if="temp_del_arr.indexOf(obj.Corp_Num) == -1" :row="obj" :checkList="tableHeaders" @del_recommend="del_recommend" />
              </tr>
            </tbody>
          </table>
          <div class="p-2">
            <code v-if="!jsonObj.total_count">
              <div class="lang" v-html="lan.no_data"></div>
            </code>
          </div>
        </div>
        <!-- end .table-responsive -->
      </div>
      <!-- end .table-rep-plugin-->
    </div>
    <!-- end .responsive-table-plugin-->
    <div v-else>
      <code>{{(viewParams.name!='corp_history'? "データ取得に失敗しました。":"過去営業歴がありません。")}}</code>
    </div>
  </div>
  <!-- container -->
</template>

<script>
import ListTableRow3 from "./ListTableRow3.vue";
import axios from "axios";
import { inject } from "vue";
export default {
  name: "ListTable",
  props: {
    viewParams: {
      name: {
        type: String,
        default: "",
      },
      apiUrl: {
        type: String,
        default: "",
      },
      randIndex: {
        type: Number,
        default: 0,
      },
      articleView: String,
      articleParamId: String,
    },
    area_name_en: {
      type: String,
      default: "",
    },
    area_name_jp: {
      type: String,
      default: "",
    },
    building_id:{
      type:String,
      default:''
    }
  },
  components: {
    ListTableRow3,
  },
  data() {
    return {
      isRand: 0,
      cur_recommend: 0,
      offset: 0,
      options: {},
      options_bar: {},
      series: null,
      series_bar: null,
      options_line: {},
      series_line: null,

      listStrage: {
        query: "",
        limit: 20,
        paged: 1,
      },
      maxPage: 0,
      checkKeys: [],
      jsonObj: {
        offset: 0,
        count: 0,
        total_count: 0,
        data: [Object],
      },
      tableHeaders: [],
      listData: [Object],
      isFocus: -1,
      ddMenu: false,
      jsonErr: false,
      isLoading: true,
      downloadAlert: "",
      cur_lan: localStorage.getItem("cur_lan") != "jp" ? "en" : "jp",
      lan: inject("global").language_data,
      lan_help: inject("global").language_help_data,
      queryObj:{
        limit:20,
        expand:'',
        industry:'',
        isLease:''
      },
      temp_del_arr:[],
      industry_type:[
        {id:'R',name:'サービス業（他に分類されないもの）'},
        {id:'K',name:'不動産業，物品賃貸業'},
        {id:'S',name:'公務（他に分類されるものを除く）'},
        {id:'T',name:'分類不能の産業'},
        {id:'P',name:'医療，福祉'},
        {id:'I',name:'卸売業，小売業'},
        {id:'L',name:'学術研究，専門・技術サービス業'},
        {id:'M',name:'宿泊業，飲食サービス業'},
        {id:'D',name:'建設業'},
        {id:'G',name:'情報通信業'},
        {id:'O',name:'教育，学習支援業'},
        {id:'B',name:'漁業'},
        {id:'N',name:'生活関連サービス業，娯楽業'},
        {id:'E',name:'製造業'},
        {id:'Q',name:'複合サービス事業'},
        {id:'A',name:'農業，林業'},
        {id:'H',name:'運輸業，郵便業'},
        {id:'J',name:'金融業，保険業'},
        {id:'C',name:'鉱業，採石業，砂利採取業'},
        {id:'F',name:'電気・ガス・熱供給・水道業'},
      ]
    };
  },
  mounted() {

    axios
      .get(this.viewParams.apiUrl, {
        params: {
          ...this.queryObj
        },
      })
      .then((response) => {
        this.jsonObj = response.data;
        this.tableHeaders = this.jsonObj.headers;
        this.listData = this.jsonObj.data;
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error), (this.jsonErr = true), (this.isLoading = false);
      });
  },
  methods: {
    del_recommend(Corp_Num){

      this.temp_del_arr.push(Corp_Num);
      axios.get('/v1/del_build_recommend',{
        params:{
          BuildingID:this.building_id,
          Corp_Num:Corp_Num
        }
      }).then(res=>{
        console.log("删除成功",res)
      })
    },
    get_industry_type(){
      axios.get("/v1/corp_features_industry_type").then(res=>{
        console.log("返回值",res);
      })
    },
    download() {
      location.href =
        "https://cbredatacom.s3.ap-northeast-1.amazonaws.com/research/reports/pptx_stage/subSubAreaReportDemo.pptx";

    },
    toggleDDMenu: function () {
      this.ddMenu = !this.ddMenu;
    },
    allCheck: function () {
      this.checkKeys = this.tableHeaders;
    },
    allUncheck: function () {
      this.checkKeys = [];
    },
    toggleFocus: function (idNum) {
      this.isFocus = idNum;
    },
    search: function () {
      this.isLoading = true;
      localStorage.setItem(
        this.viewParams.name + "_limit",
        this.listStrage.limit
      );
      sessionStorage.setItem(
        this.viewParams.name + "_query",
        this.listStrage.query
      );
      sessionStorage.setItem(this.viewParams.name + "_paged", 1);
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            ...this.queryObj
          },
        })
        .then(
          (response) => (
            (this.jsonObj = response.data),
            (this.tableHeaders = this.jsonObj.headers),
            (this.listData = this.jsonObj.data),
            (this.maxPage = Math.ceil(
              this.jsonObj.total_count / this.listStrage.limit
            )),
            (this.listStrage.paged = 1),
            (this.isLoading = false)
          )
        )
        .catch((error) => {
          console.log(error), (this.jsonErr = true), (this.isLoading = false);
        });
    },
    pagenateNum: function (num) {
      this.isLoading = true;
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: (num - 1) * this.listStrage.limit, //開始位置
            limit: this.listStrage.limit, //表示件数
            q: this.listStrage.query, //キーワード
          },
        })
        .then(
          (response) => (
            (this.jsonObj = response.data),
            (this.tableHeaders = this.jsonObj.headers),
            (this.listData = this.jsonObj.data),
            (this.maxPage = Math.ceil(
              this.jsonObj.total_count / this.listStrage.limit
            )),
            (this.listStrage.paged =
              Math.round(this.jsonObj.offset / this.listStrage.limit) + 1),
            sessionStorage.setItem(
              this.viewParams.name + "_paged",
              this.listStrage.paged
            ),
            (this.isLoading = false)
          )
        )
        .catch((error) => {
          console.log(error), (this.jsonErr = true), (this.isLoading = false);
        });
    },
    pagenateKey: function (key) {
      switch (key) {
        case "next":
          if (this.listStrage.paged < this.maxPage) {
            this.isLoading = true;
            this.offset = this.listStrage.limit * this.listStrage.paged;
          }
          break;
        case "prev":
          if (this.listStrage.paged > 1) {
            this.isLoading = true;
            this.offset = this.listStrage.limit * (this.listStrage.paged - 2);
          }
          break;
        case "first":
          if (this.listStrage.paged > 1) {
            this.isLoading = true;
            this.offset = 0;
          }
          break;
        case "last":
          if (this.listStrage.paged < this.maxPage) {
            this.isLoading = true;
            this.offset = this.listStrage.limit * (this.maxPage - 1);
          }
          break;
        default:
          this.isLoading = false;
          console.log("Non-existent key");
      }
      if (this.isLoading) {
        axios
          .get(this.viewParams.apiUrl, {
            params: {
              offset: this.offset, //開始位置
              limit: this.listStrage.limit, //表示件数
              q: this.listStrage.query, //キーワード
            },
          })
          .then(
            (response) => (
              (this.jsonObj = response.data),
              (this.tableHeaders = this.jsonObj.headers),
              (this.listData = this.jsonObj.data),
              (this.maxPage = Math.ceil(
                this.jsonObj.total_count / this.listStrage.limit
              )),
              (this.listStrage.paged =
                Math.round(this.jsonObj.offset / this.listStrage.limit) + 1),
              sessionStorage.setItem(
                this.viewParams.name + "_paged",
                this.listStrage.paged
              ),
              (this.isLoading = false)
            )
          )
          .catch((error) => {
            console.log(error), (this.jsonErr = true), (this.isLoading = false);
          });
      }
    },
    pageHide: function (num) {
      if (num < this.listStrage.paged - 4 || num > this.listStrage.paged + 4) {
        return true;
      }
    },
    listDownload: function () {
      let xmldata;
      axios
        .post(
          this.viewParams.apiUrl + "download",
          {
            data: this.listData,
            items: this.checkKeys,
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          xmldata = response.data;
          this.$nextTick(() => {
            let blob = new Blob([xmldata]);
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "corps.xlsx";
            link.click();
          });
          this.downloadAlert = "";
          this.isLoading = false;
        })
        .catch((error) => {
          this.downloadAlert = this.$language('ダウンロードに失敗しました。');
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  watch: {
    checkKeys(newCheckKeys) {
      localStorage.setItem(
        this.viewParams.name + "_checked",
        JSON.stringify(newCheckKeys)
      );
    },
    isLoading: function () {
      if (!this.isLoading) {
        this.$nextTick(() => {
          window.parent.postMessage(["h"], "*");
        });
      } else {
        //ローディング開始のタイミングでisFocusをリセット
        this.isFocus = -1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-inline {
  flex-wrap: nowrap;
}
.btn-download {
  margin-top: -20px;
  margin-right: 0;
}
.toolbar-download {
  margin-left: auto;
  margin-right: 1.5rem;
}

.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

.toolbar-search{
  margin-left: 30px;
}

@media screen and (max-width: 576px) {
  .toolbar-search {
    flex: 1;
  }

  .toolbar-download {
    justify-content: flex-end;
    margin-bottom: 0.75rem;
    margin-right: 0;
    order: -1;
    width: 100%;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: 0.5em;
    width: 100%;
  }
}

.form-search {
  position: relative;
}

.form-search::before {
  content: "\f002";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  height: 1em;
  width: 1em;
}

.ddmenu-modal {
  position: relative;
  z-index: 128;
}

.ddmenu {
  background: #fff;
  border-radius: 0.15rem;
  left: 50% !important;
  padding: 2.5rem 2.5rem 1.5rem;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  width: 80vw;
  z-index: 16;
}

.ddmenu .btn-dd-close {
  position: absolute !important;
  right: -0.5rem;
  top: -0.5rem;
}

.dropdown-menu.show {
  align-content: flex-start;
  animation: none !important;
  display: flex;
  flex-wrap: wrap;
  left: auto !important;
  margin-bottom: 1rem;
  max-height: 40rem;
  overflow-y: scroll !important;
  position: static !important;
  top: auto !important;
  width: 100%;
}

.dropdown-menu.show li {
  flex: 0 1 32.5%;
  word-break: break-all;
}

.ddmenu-bg {
  background: rgba(0, 0, 0, 0.25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.fade-leave-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}
@media screen and (max-width: 1200px) {
  .col-4 {
    width: 100%;
    flex: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .dropdown-menu.show li {
    flex-basis: 50%;
  }
}

@media screen and (max-width: 500px) {
  .dropdown-menu.show li {
    flex-basis: 100%;
  }
}

.table-responsive {
  overflow: auto !important;
}

table.focus-on tbody tr:hover,
table.focus-on tfoot tr:hover {
  cursor: unset;
}

.table thead tr:last-child th {
  box-shadow: 0 -0.1rem #dee2e6 inset;
  border-bottom: none;
  position: sticky;
  top: -1px;
  z-index: 8;
}

.table.table-striped td {
  min-width: 14.5em;
  white-space: normal !important;
  word-wrap: break-word;
}

.table.table-striped .table-focus-on-td {
  align-items: center;
  min-width: auto;
  padding: 0.35rem !important;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: 0.15s;
}

// transition
.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: #fff !important;
}
.table-striped thead th {
  background: #003f2d !important;
  color: #fff;
}
.emptyd {
  background: #003f2d !important;
  color: #fff;
}
.recommend {
  background: #003f2d !important;
  color: #fff;
}
</style>